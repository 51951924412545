class TimezoneDetector {
    /**
     * Set timezones.
     *
     * @public
     * @version 1.0
     * @param   {{id: number, offset: number, name: string}[]} timezones
     * @return  {TimezoneDetector}
     */
    static set(timezones) {
        this.timezones = timezones;

        return this;
    }

    /**
     * Auto detect timezone ID.
     *
     * Detection ways:
     *  1) Detect by timezone name.
     *  2) Detect by timezone region.
     *  NOTE: Timezone Detection via offset was deprecated since it does not account for daylight savings and it was detecting wrong timezones
     *
     * @return {?number} Timezone ID or null
     */
    static detect() {
        const browser = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            offset: -new Date().getTimezoneOffset()
        };

        // If Timezone Name Matches and Offset Matches.
        for (let i = 0; i < this.timezones.length; i += 1) {
            if (browser.timezone
                && this.timezones[i].name.indexOf(browser.timezone) !== -1
                && browser.offset === this.timezones[i].offset) {
                return this.timezones[i].id;
            }
        }

        // If Only Timezone Name Matches.
        for (let i = 0; i < this.timezones.length; i += 1) {
            if (browser.timezone
                && this.timezones[i].name.indexOf(browser.timezone) !== -1) {
                return this.timezones[i].id;
            }
        }

        return null;
    }
}

module.exports = TimezoneDetector;
