const TimezoneDetector = require('../sharable/TimezoneDetector');

(async () => {
    const ignorePathNames = ['/member/activate_account', '/signup', '/login', 'signup/iframe'];

    // Skip timezone detection on public pages.
    if (ignorePathNames.indexOf(window.location.pathname) > -1) {
        return;
    }

    try {
        if (sessionStorage.getItem('timezone_offset') !== `${new Date().getTimezoneOffset()}`) {
            const timezones = await (await fetch('/api/timezones')).json();

            const detectedTimezoneID = TimezoneDetector.set(timezones.data).detect();

            if (detectedTimezoneID) {
                await fetch(`/api/timezones/set/${detectedTimezoneID}`, { credentials: 'include' });
                sessionStorage.setItem('timezone_offset', `${new Date().getTimezoneOffset()}`);
            }
        }
    } catch (e) {
        // Silence is golden.
    }
})();
